@import 'common-flagship_tasting_room';

//stylelint-disable
.pswp__img {
	background: $color-product_image-background;
}

.b-dialog-btn{
	&.m-alt {
		border:none;
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background: var(--button_alt-background_color, $color-button_alt-background);
				color: var(--button_alt-text_color, $color-button_alt-text);
			}
		}
		.b-dialog-btn_text {
			@include t-link_underlined($state: default, $underline-offset: 5px, $font-weight: inherit);
			@include t-link_underlined($state: hover);
			letter-spacing: inherit;
		}
	}
}


.b-product_tile {
	font-family: $font-alt;
	&-name{
		font-family: $font-alt;
	}
}

.b-product_quantity-input {
	background: transparent;
	&:disabled {
		background: transparent;
	}
}


.b-header {
	background-color: $color-shade_1;
	color: $color-primary;
	border-bottom: 2px solid $color-secondary;
	font-size: 16px;
	&-background{
		border: 0;
	}
	&:not(.m-active) {
		height: auto;
	}
	&-navigation_inner {
		background-color: $color-shade_1;
	}
	&.m-stuck{
		background-color: $color-shade_1;
		color: $color-primary;
	}
	&.m-transparent {
		&:not(.m-inverted) {
			&:not(.m-active) {
				&:not(.m-stuck) {
					&:not(.m-hamburger_opened) {
						background-color: $color-shade_1;
						color: $color-primary;
					}
				}
			}
		}
	}
}

.b-age_gate{
	&-inner {
		color: $color-primary;
	}
	&-button {
		color: $color-primary;
		border-color: $color-primary;

		&:hover {
			background-color: $color-primary;
			color: $color-shade_1;
		}
	}
}

.b-categories_navigation{
	&-item_1 {
		font-family: $font-alt;
		&:hover {
			.b-categories_navigation-group_1 {
				background-color: $color-shade_1;
				color: $color-primary;
			}
		}
		&:after {
			border-bottom: 3px solid $color-secondary;
		}
	}
	&-group_1{
		border-top: 1px solid $color-secondary;
		&[aria-hidden="false"]{
			background-color: $color-shade_1;
			color: $color-primary;
		}
	}
	&-group_2{
		background-color: $color-shade_1;
	}
	&-link_1 {
		font-family: $font-alt;
	}
}


.b-carousel {
	&-control{
		border: 0;
	}
	&-pagination_control{
		border: 1px solid $color-primary;
	}

	&-title_custom{
		font-size: 2rem;
	}
}


.b-shipping_panel-inner{
	background-color: $color-shade_1;
}

.b-breadcrumbs {
    &-item {
		align-items: baseline;
		&:not(.m-current) {
			@include media(md-up) {
				@include icon('arrow-right', 8px, 8px, after) {
					background: $color-breadcrumb-separator_bg;
					margin: 2px 15px 0;
				}
			}
		}
	}
}

// #Brand logo responsive #
.b-logo-image{
	&.logo {
		&-desktop {
			@include media(sm){
				display: none;

			}
		}
		&-mobile {
			display: none;
			margin-top: -12%;
			margin-left: -12%;
			@include media(sm){
				display: block;

			}
		}
	}
}

.b-header-navigation{
	padding-top: 40px;
	@include media(sm){
		padding-top: 0;
	}
}

//favorite text hide on scroll
.b-header{
    &.m-stuck{
        .b-header-utility_item{
            &.m-favorites {
                font-size: 0;
            }
        }
    }
}

.b-footer_social-links {
	// justify-content: flex-start;
	a {
		&:not(:first-child) {
			padding: 0px 16px;
		}
	}
}

.b-product_tile {
	&.m-grid{
		.b-product_quantity {
			border-right: 2px solid $color-quantity;
			margin-bottom: 5px;
			&.m-disabled {
				border-color: rgba($color-quantity,$configuration-product_quantity-disabled-opacity);
			}
			@include media(md-up) {
				margin-bottom: 0px;
				margin-right: 5px;
				border-right: 2px solid $color-quantity;
			}
		}
	}
}


.b-carousel {
	.b-product_tile-actions {
		display: flex;
		@include media(sm) {
		display: block;
		}
	}
}

.b-header{
	&-utility_item {
		&.m-favorites:after,&.m-favorites_active:after{
			display: none;
		}
		&.m-favorites {
			@include icon(heart, $icon-width: 17px, $icon-height: 16px, $position: before);
			padding: 15px;
			&:before{
                margin-right: 10px;
            }
		}

		&.m-favorites_active {
			@include icon(wishlist-active, $icon-width: 17px, $icon-height: 16px, $position: before) {
				background: $color-accent;
			}
		}
	}
}

.b-footer{
	&_navigation-title{
		color: $color-shade_3;
	}
	&-newsletter{
		color: $color-shade_3;
	}
	&_social-title{
		color: $color-shade_3;
	}
}

.b-header{
	.b-header_login-title_link{
		&:before{
			min-width: 16px;
		}
		@include media(lg-up){
			min-width: 138px;
			padding: 12px;
		}
	}
	&.m-stuck{
		.b-header_login-title_link {
			min-width: 0px;
		}
	}
}

.b-minicart_product-remove{
	.b-button_remove{
		@include icon('close', 16px, 16px, before)
	}
}

.b-cart_product-remove{
	.b-button_remove{
		@include icon('close', 16px, 16px, before)
	}
}

.b-product_badge {
	&.m-promo {
		border: $configuration-product_badge-border_radius solid $color-secondary;
	}
}

.b-ship_to-state{
	border-bottom: 1px solid currentColor;
}

.b-product_badge {

	&.m-promo {
		background: $color-secondary;
	}
	color: $color-primary !important;
	&-image {
		background: none !important;
		border: none;
	}
	&.m-rating_points {
		border: 2px dashed $color-primary;
		border-radius: 50%;
	}
	&-abbr, &-text {
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;

		@include media(md-down) {
			font-size: 12px;
			letter-spacing: 0.12px;
			line-height: 14px;
		}
	}

	&-points {
		font-size: 32px !important;
		letter-spacing: 0.31px;

		@include media(md-down) {
			font-size: 20px !important;
			letter-spacing: 0.19px;
		}
	}
}

.b-footer {
	&-social {
		margin-bottom: 20px;
	}
}
.b-footer_social-links {
	justify-content: flex-start !important;
	@include media(md-down) {
		justify-content: center !important;
	}
}

.b-age_gate-border{
	border:7px solid rgba($color-primary,0.6);
	margin: 30px;
	padding-bottom: 30px;
	overflow: hidden;
	@include media(sm){
		margin: 15px;
		padding-bottom: 20px;
	}
}

.b-error_page {
	grid-auto-rows:auto !important;
	padding: 0 !important;
	&_outer-border {
		@extend .b-age_gate-border;
		padding: 0 !important;
	}
	&_inner-border {
		position: relative;
		margin: 15px;
		border: 3px solid rgba($color-primary,0.6);
	}
	&-title {
		font-size: 48px !important;
    	font-weight: 600 !important;
		@include media(sm){
			font-size: 40px !important;
		}
	}
	&-text {
		max-width: fit-content;
	}
	&-btn {
		border-color: $color-primary;
		margin-bottom: 60px;
		@include media(sm){
			margin-bottom: 50px;
		}
	}
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    .f-input_date-field {
		font-variant-numeric: diagonal-fractions !important;
		font-size: 20px !important;
		letter-spacing: 5px !important;
	}
}}

// Age Gate

.m-age_gate-open {
	.b-age_gate {
		.b-age_gate-header {
			display: flex;
			align-items: flex-end;
			margin: 0 auto -40px;
			position: relative;
			z-index: 1;
			justify-content: center;
			img{
				background-color: $color-secondary;
				border-radius: 90px;
				padding: 13.25px 27px;
			}
			@include media(md-up) {
				flex-grow: 1;
			}
		}

		.b-age_gate-main {
			background-color: $color-primary-background;
			border:none;
			color: $color-primary;
			outline: 2px solid  $color-primary-background;
			padding: 30px 0 20px;	
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			outline-offset: 8px;
			padding: 68px 16px 16px;
			width: calc(100% - 1rem);
			@include media(md-up) {
				outline-offset: 10px;
				padding: 68px 68px 26px;
				width: 674px;
			}
		}

		.b-age_gate-inner {
			padding: 1.5rem 1rem 0;

			@include media(md-up) {
				padding-top: 0;
				display: flex;
				flex-direction: column;
			}
		}

		.b-age_gate-title {
			margin-bottom: 16px;
			order: 0;
			color: $color-primary;
			font-weight: bold;
			font-size: 34px;
			line-height: 38px;
			margin: 0 auto 16px;
    		max-width: 306px;
			@include media(md-up) {
				font-size: 40px;
				line-height: 46px;
				max-width: unset;
			}
		}

		.b-age_gate-subtitle {
			margin-bottom: 26px;
			order: 1;

			.t-paragraph_3 {
				font-size: 18px;
				line-height: 24px;
				
				@include media(md-up) {
					font-size: 22px;
					line-height: 27px;
				}
			}
		}

		.b-age_gate-form {
			display: grid;
			grid-column-gap: 14px;
			grid-template-columns: repeat(3, 108px);
			order: 2;
			margin-bottom: 32px;
			@include media(sm) {
				grid-template-columns: repeat(2, 80px) 108px;
			}
			.age-gate-form-field {
				width: 100%;
			}
			.age-gate-input {
				border: 2px solid $color-primary;
				width: 100%;

				&:placeholder-shown {
					border-color: rgba(34, 56, 58, 0.5);
					color: rgba(34, 56, 58, 0.5);
					margin-right: 0;

					&:focus {
						border-color: $color-primary;
						color: $color-primary;
					}
				}
			}

			.b-age_gate-submit {
				background-color: $color-secondary;
				color: $color-age_gate-copy;
				font-size: 18px;
				border-color: $color-secondary;
				cursor: pointer;
				grid-column: 1/span 3;
				justify-self: center;
				margin-top: 32px;
				min-width: auto;
				width: 100%;
				@include media(md-up){
					max-width: 152px;
				}
			}
		}

		.b-age_gate-description {
			margin-bottom: 0;
			color: $color-age_gate-shade;
			order: 3;
		}

		.too-young {
			font-size: 24px;
			line-height: 32px;
			margin: auto;
			max-width: 70%;
			order: 2;

			@include media(sm) {
				font-size: 20px;
				line-height: 30px;
				max-width: 350px;
			}
		}
		.b-age_gate-footer{
			@include media(md-up){
					display: flex;
					flex-grow: 1;
					align-items: flex-end;
					justify-content: center;
					width: 100%;
					
			}
			.b-footer-bottom_info{
				padding: 0;
				@include media(md-up){
					display: flex;
					align-items: center;
					width: 100%;
					padding: 30px 60px;
				}
			}
			.b-footer_legal-link, .b-footer-copyright{
				color:$color-age_gate-copy;
				opacity: 1;
			}
		
			.b-footer_legal-link{
				font-weight: bold;
			}
		}
	}
}

.b-ship_to-link, .b-header-navigation_link.m-stores{
	text-transform: uppercase;
}