// General site styles
// Be very careful! It affects all elements and styles!

html {
	background: $color-shade_1;
	color: $color-text;
	direction: ltr;
	font: #{$size-font}/#{$size-line} $font-main;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
	-ms-text-size-adjust: 100%; // stylelint-disable-line
	-webkit-text-size-adjust: 100%; // stylelint-disable-line
}

body {
	box-sizing: border-box;
	margin: 0;
	min-width: $global-content-width-min;
	overflow-y: scroll;
	padding: 0.01px 0 0; // Android UI status bar overlap browser window. Reproducible on Samsung S9 Chrome
}

a {
	color: inherit;
	text-decoration: none;

	// We should include hover on global elements and elements
	// otherwise iOS will fire hover on first tap, click on second
	// this is critical for components like back to top button
	@include hover-supported {
		&:hover {
			text-decoration: underline;
		}
	}
}

button {
	appearance: none;
	background: none;
	border: 0;
	border-radius: 0;
	padding: 0;
}

img {
	overflow: hidden;
}

// Use to show anchor elements down below sticky header
:target {
	&::before {
		content: '';
		display: block;
		height: 80px;
		margin-top: -80px;
		visibility: hidden;
	}
}
