.b-footer {
	@mixin _footer-divider {
		border-top: 1px solid rgba($color-footer-border, 0.15);
	}
	@include _footer-divider;
	background: $color-header-background; //Edge doesn't work with combination var() + url()
	color: var(--color-footer-text, $color-footer-text);
	display: grid;
	font-family: $font-main;
	gap: 0 var(--grid-gutter);
	grid-template:
		"contacts   customer_service   need_help   email"
		"contacts   customer_service   need_help   social" 1fr
		"bottom     bottom             bottom      bottom"/1fr 1fr 1fr 1fr;
	padding-left: grid-margin(lg);
	padding-right: grid-margin(lg);

	@include media(md) {
		grid-template:
			'contacts customer_service'
			'email    need_help'
			'social   .'
			'bottom   bottom'
			/ 1fr     1fr;
		row-gap: rh(10);
	}

	@include media(md-wide) {
		grid-template:
			'contacts customer_service need_help'
			'email    social           .'
			'bottom   bottom           bottom'
			/ 1fr     1fr              1fr;
	}

	@include media(sm) {
		grid-template:
			'email'
			'contacts'
			'customer_service'
			'need_help'
			'social'
			'bottom' /
			minmax(0, 1fr);
		padding-left: grid-margin(sm);
		padding-right: grid-margin(sm);
	}

	&-email_subscription {
		padding: rh(12 0 0);
		max-width: 320px;
		@include media(md) {
			padding: 0;
		}

		@include media(sm) {
			margin: 0 -#{grid-margin(sm)};
			padding: rh(8 3);
			max-width: initial;
		}
	}

	&-contacts {
		grid-area: contacts;

		.content-asset {
			padding: rh(13 0 0);

			@include media(sm) {
				padding: rh(6 0 8);
			}
		}
	}

	&-customer_service {
		grid-area: customer_service;

		.content-asset {
			padding: rh(13 0 0);

			@include media(sm) {
				padding: rh(6 0 3);
			}
		}
	}

	&-need_help {
		grid-area: need_help;

		.content-asset {
			padding: rh(13 0 0);

			@include media(md) {
				padding: 0;
			}

			@include media(md-wide) {
				padding: rh(13 0 0);
			}

			@include media(sm) {
				padding: rh(6 0 8);
			}
		}
	}

	&-social {
		grid-area: social;

		.content-asset {
			padding: rh(13 0 0);

			@include media(sm) {
				padding: rh(6 0);
			}
		}
	}

	&-contacts,
	&-customer_service,
	&-need_help,
	&-social {
		.content-asset {
			@include media(sm) {
				@include _footer-divider;

				text-align: center;
			}
		}
	}

	&-bottom_info {
		@include _footer-divider;

		display: flex;
		flex-flow: row-reverse;
		grid-area: bottom;
		justify-content: space-between;
		padding: rh(6 0);

		@include media(md) {
			display: block;
		}

		@include media(sm) {
			align-items: center;
			flex-flow: column;
		}
	}

	&-newsletter_title {
		@include t-heading_5;

		margin-bottom: rh(3);

		@include media(sm) {
			margin-bottom: rh(4);
			text-align: center;
		}
	}

	&-newsletter_description {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: rh(7);

		@include media(lg) {
			padding-right: 10px;
		}

		@include media(sm) {
			text-align: center;
		}
	}

	&-copyright {
		font-size: 14px;
		letter-spacing: 0.14px;
		line-height: 22px;
		margin: 0;
		opacity: 0.7;

		@include media(sm) {
			font-size: 12px;
			text-align: center;
		}
	}
}

.enable-footer-image {
	background: $color-footer-background url('./images/footer-bg.jpg') no-repeat center center / cover; //Edge doesn't work with combination var() + url()
	color: var(--color-footer-text, $color-footer-text);

	@include media(md) {
		background-image: url('./images/footer-bg-tablet.jpg');
	}

	@include media(sm) {
		background-image: url('./images/footer-bg-mobile.jpg');
	}
}
